<header class="header">
  <div class="header__wrapper">
    <img
      (click)="closeMenu()"
      routerLink="/"
      src="/assets/icons/logo.png"
      alt="Logo"
      class="header__logo"
    />

    <div class="mobile-nav">
      <div
        *ngIf="isAuthorized"
        (click)="openCart(); closeMenu()"
        class="header__icon-wrapper"
      >
        <img src="/assets/icons/cart.svg" class="header__cart-icon" />
        <div *ngIf="cartCount" class="header__cart-badge">
          {{ cartCount }}
        </div>
      </div>
      <div (click)="openMenu()" class="burger-icon">
        <div class="line"></div>
        <div class="line centered-line"></div>
        <div class="line"></div>
      </div>
    </div>

    <div class="header__nav">
      <ng-container *ngIf="isAuthorized">
        <img
          class="header__user-icon"
          routerLink="/my-account/orders"
          src="/assets/icons/order-list.svg"
          alt="order-list-icon"
        />
        <img
          class="header__user-icon"
          routerLink="/my-account/details"
          src="/assets/icons/user-icon.svg"
          alt="user-icon"
        />
        <div (click)="openCart()" class="header__icon-wrapper">
          <img src="/assets/icons/cart.svg" class="header__cart-icon" />
          <div *ngIf="cartCount" class="header__cart-badge">
            {{ cartCount }}
          </div>
        </div>
      </ng-container>
      <global-select></global-select>
      <global-select type="lang"></global-select>
      <button *ngIf="isAuthorized" (click)="logout()" class="header__sign-in">
        {{ "header.log-out" | translate }}
      </button>
      <button
        routerLink="/account"
        class="header__sign-in"
        *ngIf="!isAuthorized"
      >
        {{ "header.sign-in" | translate }}
      </button>
    </div>
  </div>
</header>

<div [class.open]="isMenuOpen" class="menu-container">
  <div [class.show-content]="isMenuOpen" class="menu-wrap">
    <global-select></global-select>
    <global-select type="lang"></global-select>
    <ng-container *ngIf="isAuthorized">
      <div
        (click)="closeMenu()"
        routerLink="/my-account/orders"
        class="nav-item"
      >
        <img
          class="header__user-icon"
          src="/assets/icons/order-list.svg"
          alt="order-list-icon"
        />
        {{ "header.orders" | translate }}
      </div>
      <div
        (click)="closeMenu()"
        routerLink="/my-account/details"
        class="nav-item"
      >
        <img
          class="header__user-icon"
          src="/assets/icons/user-icon.svg"
          alt="user-icon"
        />
        {{ "header.profile" | translate }}
      </div>
      <div
        (click)="logout(); closeMenu()"
        *ngIf="isAuthorized"
        class="nav-item fill-item"
      >
        {{ "header.log-out" | translate }}
      </div>
    </ng-container>
    <div
      *ngIf="!isAuthorized"
      routerLink="/account"
      (click)="closeMenu()"
      class="nav-item fill-item"
    >
      {{ "header.sign-in" | translate }}
    </div>
  </div>
</div>
