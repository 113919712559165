<section class="slider">
  <div class="slider__wrapper">
    <div class="slider__text-wrapper">
      <div class="slider__title">{{ "pages.home.title-2" | translate }}</div>

      <div class="slider__text">
        {{ "pages.home.description" | translate }}
      </div>
    </div>

    <div class="slider__slides-wrapper">
      <div class="slider__slider-item" *ngFor="let item of sliderItems">
        <div class="slider__content-wrapper">
          <img
            src="/assets/images/slider-background.svg"
            alt=""
            class="slider__background-image"
          />

          <img
            class="slider__inner-image"
            src="/assets/icons/{{ item.iconName }}.svg"
            alt="Icon"
          />

          <h5 class="slider__inner-title">
            {{ "pages.home." + item.iconName + "-title" | translate }}
          </h5>

          <p class="slider__inner-text">
            {{ "pages.home." + item.iconName + "-description" | translate }}
          </p>
        </div>
      </div>
    </div>

    <!-- <ngx-slick-carousel class="slider__slider" [config]="sliderConfig">
      <div
        ngxSlickItem
        class="slider__slider-item"
        *ngFor="let item of sliderItems"
      >
        <div class="slider__content-wrapper">
          <img
            src="/assets/images/slider-background.svg"
            alt=""
            class="slider__background-image"
          />

          <img
            class="slider__inner-image"
            src="/assets/icons/{{ item.iconName }}.svg"
            alt="Icon"
          />

          <h5 class="slider__inner-title">{{ item.title }}</h5>

          <p class="slider__inner-text">
            {{ item.text }}
          </p>
        </div>
      </div>
    </ngx-slick-carousel> -->
  </div>
</section>
