import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { ApiService } from '@core/services/api.service';
import { LocalNotificationService } from '@core/services/local-notification.service';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
})
export class ContactFormComponent {
  public form: FormGroup;
  public isLoading: boolean;

  constructor(
    private readonly apiService: ApiService,
    private readonly localNotificationService: LocalNotificationService
  ) {}

  ngOnInit() {
    this.initForm();
  }

  public initForm() {
    this.form = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
      subject: new FormControl(null, Validators.required),
      message: new FormControl(null, Validators.required),
    });
  }

  public submit() {
    this.isLoading = true;

    let dto = {
      email: this.form.get('email').value,
      subject: this.form.get('subject').value,
      message: this.form.get('message').value,
    };

    this.form.reset();

    this.apiService
      .executeRequest('post', '/feedback', dto)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        () => {
          this.localNotificationService.showNotification(
            'shared.local-notifications.success-send',
            'toast-success'
          );
        },
        (error) => {
          this.localNotificationService.showNotification(
            error?.error?.message,
            'toast-error'
          );
        }
      );
  }
}
