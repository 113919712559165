<div class="log-in-form">
  <div class="log-in-form__corner-wrapper">
    <div class="log-in-form__wrapper">
      <form [formGroup]="form">
        <div class="log-in-form__title">
          {{ "pages.auth.login-title" | translate }}
        </div>

        <div class="log-in-form__text">
          {{ "pages.auth.login-description" | translate }}
        </div>

        <div class="log-in-form__form-control">
          <input
            type="email"
            class="log-in-form__input"
            id="email"
            formControlName="email"
            placeholder="{{ 'shared.placeholders.email' | translate }}"
          />
        </div>

        <div class="log-in-form__form-control">
          <div class="log-in-form__icon-wrapper">
            <input
              id="passwordInput"
              formControlName="password"
              type="password"
              class="log-in-form__input"
              placeholder="{{ 'shared.placeholders.password' | translate }}"
            />

            <img
              tooglePass
              [tooglePassTriger]="tooglePassTriger"
              (click)="emitTooglePass('password')"
              src="/assets/icons/show-password-icon.svg"
              id="password"
              class="log-in-form__icon-password"
            />
          </div>
        </div>

        <a routerLink="/forgot-password" class="log-in-form__forgot-pass">
          {{ "pages.auth.forgot-link" | translate }}
        </a>

        <button
          [disabled]="form.invalid"
          (click)="submit()"
          class="log-in-form__submit"
        >
          <span *ngIf="!isLoading">{{
            "shared.buttons.submit" | translate
          }}</span>
          <app-loader *ngIf="isLoading"></app-loader>
        </button>
      </form>
    </div>
  </div>
</div>
