import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { LocalStorageService } from './local-storage.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    private readonly http: HttpClient,
    private readonly storageService: LocalStorageService
  ) {}

  public executeRequest(
    method: string,
    url: string,
    data: object = {},
    checkAuth: boolean = true,
    responseType: 'json' | 'blob' = 'json'
  ): Observable<any> {
    let headers = new HttpHeaders();

    headers = headers.append('Currency', this.storageService.currency);
    headers = headers.append('Language', this.storageService.language);

    if (checkAuth) {
      headers = headers.append('Authorization', this.authHeader());
    }

    return this.http
      .request(method.toUpperCase(), `${environment.apiUrl}${url}`, {
        ...((method === 'post' || method === 'patch' || method === 'put') && {
          body: data,
        }),
        headers: headers,
        responseType,
      })
      .pipe(
        map((res: Object) => {
          return res;
        }),
        catchError((error) => {
          throw error;
        })
      );
  }

  private authHeader(): string {
    return `Bearer ${this.storageService.token}`;
  }
}
