<a
  [href]="content.href"
  *ngIf="content.isExternal"
  target="_blank"
  rel="noopener noreferrer"
  (click)="onLinkClick(content)"
  >{{ "navigation." + content.text | translate }}</a
>
<a
  [routerLink]="[content.href]"
  *ngIf="!content.isExternal && !content.anchor"
  (click)="onLinkClick(content)"
  >{{ "navigation." + content.text | translate }}</a
>
<a
  *ngIf="!content.isExternal && content.anchor"
  (click)="onLinkClick(content)"
  >{{ "navigation." + content.text | translate }}</a
>
