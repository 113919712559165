import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IInput } from '@core/interfaces';

@Component({
  selector: 'app-form-control',
  templateUrl: './form-control.component.html',
})
export class FormControlComponent {
  @Input() public conf!: IInput;
  @Input() public form!: FormGroup;
}
