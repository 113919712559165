import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '@core/services/api.service';
import { CartService } from '@core/services/cart.service';
import { LocalNotificationService } from '@core/services/local-notification.service';
import { LocalStorageService } from '@core/services/local-storage.service';
import { SessionService } from '@core/services/session.service';
import { finalize, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-log-in-form',
  templateUrl: './log-in-form.component.html',
})
export class LogInFormComponent implements OnInit {
  public form: FormGroup;
  public formForgotPass: FormGroup;
  public isForgotPass: boolean;
  public tooglePassTriger: EventEmitter<string>;
  public isLoading: boolean;

  public get email() {
    return this.form.get('email');
  }

  constructor(
    private readonly apiService: ApiService,
    private readonly localNotificationService: LocalNotificationService,
    private readonly cartService: CartService,
    private readonly storageService: LocalStorageService,
    private readonly router: Router,
    private readonly sessionService: SessionService
  ) {
    this.tooglePassTriger = new EventEmitter();
  }

  ngOnInit() {
    this.initForms();
  }

  public initForms() {
    this.form = new FormGroup({
      email: new FormControl(null, [Validators.required]),
      password: new FormControl(null, [
        Validators.required,
        Validators.minLength(12),
      ]),
    });

    this.formForgotPass = new FormGroup({
      email: new FormControl(null, Validators.required),
    });
  }

  public emitTooglePass(id: string) {
    this.tooglePassTriger.emit(id);
  }

  public submit() {
    this.isLoading = true;

    const dto = {
      email: this.email.value,
      password: this.form.get('password').value,
    };

    this.apiService
      .executeRequest('post', '/auth/login', dto)
      .pipe(
        mergeMap((res: { access_token: string }) => {
          this.storageService.token = res.access_token;
          this.storageService.email = this.email.value;
          this.localNotificationService.showNotification(
            'shared.local-notifications.welcome',
            'toast-success'
          );
          this.runSessionExpTimer(res.access_token);
          this.router.navigate(['/']);
          return this.cartService.getCart();
        }),
        finalize(() => (this.isLoading = false))
      )
      .subscribe(
        (res) => {
          this.cartService.setCartCount(res.cart.length);
        },
        (error) => {
          if (error?.error?.statusCode === 401) {
            this.localNotificationService.showNotification(
              'shared.local-notifications.wrong-cred',
              'toast-error'
            );
          } else if (
            JSON.stringify(error.error.errorMessages) ===
            '[{"email":"Must be an email"}]'
          ) {
            this.localNotificationService.showNotification(
              'shared.local-notifications.wrong-email',
              'toast-error'
            );
          } else {
            this.localNotificationService.showNotification(
              error?.error?.message ||
                JSON.stringify(error.error.errorMessages),
              'toast-error'
            );
          }
        }
      );
  }

  private runSessionExpTimer(token: string) {
    this.sessionService.runSessionExpTimer(token);
  }
}
