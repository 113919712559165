import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reviews-slider',
  templateUrl: './reviews-slider.component.html',
})
export class ReviewsSliderComponent implements OnInit {
  public sliderConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    prevArrow:
      '<button type="button" class="reviews-slider__prev"><img src="/assets/icons/arrow-left.svg"></img></button>',
    nextArrow:
      '<button type="button" class="reviews-slider__next"><img src="/assets/icons/arrow-right.svg"></img></button>',
  };

  public sliderItems = [
    {
      iconName: 'reviews-1.svg',
      value: 'reviews-1',
      title: 'Alun B.',
    },
    {
      iconName: 'reviews-2.png',
      value: 'reviews-2',
      title: 'Rodney B.',
    },
    {
      iconName: 'reviews-3.png',
      value: 'reviews-3',
      title: 'Henri A.',
    },
  ];

  constructor() {}

  ngOnInit(): void {}
}
