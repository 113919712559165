<div class="policies">
  <img
    src="/assets/images/contact-background.svg"
    alt=""
    class="policies__background"
  />

  <h2 class="main-title">{{ "pages.privacy-policy.title" | translate }}</h2>

  <p class="centered-text">
    {{ "pages.privacy-policy.text-1" | translate }} <br /><br />
    {{ "pages.privacy-policy.text-2" | translate }}
  </p>

  <div class="title">{{ "pages.privacy-policy.text-3" | translate }}</div>

  <p>{{ "pages.privacy-policy.text-4" | translate }} <br /><br /></p>

  <p class="list">
    <strong>{{ "pages.privacy-policy.text-5" | translate }}</strong>
    {{ "pages.privacy-policy.text-6" | translate }}<br />

    ● {{ "pages.privacy-policy.text-7" | translate }}<br />
    ● {{ "pages.privacy-policy.text-8" | translate }}<br />
    ● {{ "pages.privacy-policy.text-9" | translate }}<br />
    ● {{ "pages.privacy-policy.text-10" | translate }}<br />
    ● {{ "pages.privacy-policy.text-11" | translate }} <br />

    <strong>{{ "pages.privacy-policy.text-12" | translate }}</strong>
    {{ "pages.privacy-policy.text-13" | translate }}<br />

    ● {{ "pages.privacy-policy.text-14" | translate }}<br />
    ● {{ "pages.privacy-policy.text-15" | translate }}<br />
    ● {{ "pages.privacy-policy.text-16" | translate }}<br />
    ● {{ "pages.privacy-policy.text-17" | translate }}<br />
    ● {{ "pages.privacy-policy.text-18" | translate }} <br />

    <strong>{{ "pages.privacy-policy.text-19" | translate }}</strong>
    {{ "pages.privacy-policy.text-20" | translate }}<br />

    ● {{ "pages.privacy-policy.text-21" | translate }}<br />
    ● {{ "pages.privacy-policy.text-22" | translate }}<br />
    ● {{ "pages.privacy-policy.text-23" | translate }}<br />
  </p>

  <div class="title">{{ "pages.privacy-policy.text-24" | translate }}</div>

  <p>{{ "pages.privacy-policy.text-25" | translate }} <br /><br /></p>
  <p class="list">
    ● {{ "pages.privacy-policy.text-26" | translate }}<br />
    ● {{ "pages.privacy-policy.text-27" | translate }}<br />
    ● {{ "pages.privacy-policy.text-28" | translate }}<br />
    ● {{ "pages.privacy-policy.text-29" | translate }}<br />
    ● {{ "pages.privacy-policy.text-30" | translate }} <br />
    ● {{ "pages.privacy-policy.text-31" | translate }} <br /><br />

    {{ "pages.privacy-policy.text-32" | translate }} <br />
  </p>

  <div class="title">{{ "pages.privacy-policy.text-33" | translate }}</div>

  <p>{{ "pages.privacy-policy.text-34" | translate }} <br /></p>

  <p class="list">
    {{ "pages.privacy-policy.text-35" | translate }} <br />
    ● {{ "pages.privacy-policy.text-36" | translate }} <br />
    ● {{ "pages.privacy-policy.text-37" | translate }} <br />
    ● {{ "pages.privacy-policy.text-38" | translate }} <br /><br />
    {{ "pages.privacy-policy.text-39" | translate }}
  </p>

  <div class="title">{{ "pages.privacy-policy.text-40" | translate }}</div>

  <p>
    {{ "pages.privacy-policy.text-41" | translate }}<br /><br />
    {{ "pages.privacy-policy.text-42" | translate }}<br /><br />
    {{ "pages.privacy-policy.text-43" | translate }}<br />
  </p>

  <div class="title">{{ "pages.privacy-policy.text-44" | translate }}</div>

  <p>
    {{ "pages.privacy-policy.text-45" | translate }}<br /><br />
    {{ "pages.privacy-policy.text-46" | translate }}<br /><br />
    {{ "pages.privacy-policy.text-47" | translate }}<br />
  </p>

  <div class="title">{{ "pages.privacy-policy.text-48" | translate }}</div>

  <div class="list">
    {{ "pages.privacy-policy.text-49" | translate }}<br />
    ● {{ "pages.privacy-policy.text-50" | translate }} <br />
    ● {{ "pages.privacy-policy.text-51" | translate }} <br />
    ● {{ "pages.privacy-policy.text-52" | translate }} <br />
    ● {{ "pages.privacy-policy.text-53" | translate }} <br />
    ● {{ "pages.privacy-policy.text-54" | translate }} <br />
    {{ "pages.privacy-policy.text-55" | translate }}
  </div>

  <div class="title">{{ "pages.privacy-policy.text-56" | translate }}</div>

  <p>
    {{ "pages.privacy-policy.text-57" | translate }}
  </p>

  <div class="title">
    {{ "pages.privacy-policy.text-58" | translate }}
  </div>

  <p>
    {{ "pages.privacy-policy.text-59" | translate }}
    <a href="mailto:support@guideassist.com.ua">support@guideassist.com.ua</a>.
  </p>
</div>
