<div class="policies">
  <img
    src="/assets/images/contact-background.svg"
    alt=""
    class="policies__background"
  />

  <h2 class="main-title">{{ "pages.cookie-policy.text-1" | translate }}</h2>

  <p class="centered-text">
    26/11/2024 <br /><br />
    {{ "pages.cookie-policy.text-2" | translate }}<br /><br />
    {{ "pages.cookie-policy.text-3" | translate }}
  </p>

  <div class="title">{{ "pages.cookie-policy.text-4" | translate }}</div>

  <p>
    {{ "pages.cookie-policy.text-5" | translate }}
  </p>

  <div class="title">{{ "pages.cookie-policy.text-6" | translate }}</div>

  <p class="list">
    {{ "pages.cookie-policy.text-7" | translate }} <br />
    ● {{ "pages.cookie-policy.text-8" | translate }}<br />
    ● {{ "pages.cookie-policy.text-9" | translate }}<br />
    ● {{ "pages.cookie-policy.text-10" | translate }}<br />
    ● {{ "pages.cookie-policy.text-11" | translate }}<br />
    ● {{ "pages.cookie-policy.text-12" | translate }}<br />
  </p>

  <div class="title">{{ "pages.cookie-policy.text-13" | translate }}</div>

  <p class="list">
    ● <strong> {{ "pages.cookie-policy.text-14" | translate }}</strong>
    {{ "pages.cookie-policy.text-15" | translate }} <br />
    ● <strong> {{ "pages.cookie-policy.text-16" | translate }}</strong>
    {{ "pages.cookie-policy.text-17" | translate }} <br />
    ● <strong> {{ "pages.cookie-policy.text-18" | translate }}</strong>
    {{ "pages.cookie-policy.text-19" | translate }} <br />
    ● <strong> {{ "pages.cookie-policy.text-20" | translate }}</strong>
    {{ "pages.cookie-policy.text-21" | translate }} <br />
  </p>

  <div class="title">{{ "pages.cookie-policy.text-22" | translate }}</div>

  <p>
    {{ "pages.cookie-policy.text-23" | translate }} <br /><br />
    <strong>{{ "pages.cookie-policy.text-24" | translate }}</strong>
    {{ "pages.cookie-policy.text-25" | translate }}<br /><br />
    <strong>{{ "pages.cookie-policy.text-26" | translate }}</strong>
    <br /><br />
    ● <strong>_fbp </strong>(Facebook Pixel):
    {{ "pages.cookie-policy.text-27" | translate }} <br />
    ● <strong>IDE </strong>(Google DoubleClick):
    {{ "pages.cookie-policy.text-28" | translate }} <br />
    ● <strong>fr </strong>(Facebook):
    {{ "pages.cookie-policy.text-29" | translate }} <br /><br />
  </p>
  <p>
    {{ "pages.cookie-policy.text-30" | translate }}
    <a href="https://adssettings.google.com/" target="_blank">{{
      "pages.cookie-policy.text-31" | translate
    }}</a>
    {{ "pages.cookie-policy.text-32" | translate }}
    <a href="https://www.facebook.com/ads/preferences" target="_blank">{{
      "pages.cookie-policy.text-33" | translate
    }}</a>
    {{ "pages.cookie-policy.text-34" | translate }}
  </p>

  <div class="title">{{ "pages.cookie-policy.text-35" | translate }}</div>

  <p>{{ "pages.cookie-policy.text-36" | translate }}</p>

  <div class="title">{{ "pages.cookie-policy.text-37" | translate }}</div>

  <p>{{ "pages.cookie-policy.text-38" | translate }}</p>
</div>
