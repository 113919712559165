import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScrollService {
  public scrollTop: number = 0;
  public winWidth: number = window.innerWidth;
  public winHeight: number = window.innerHeight;

  private readonly scrollYChangedSubject = new Subject<number>();
  private readonly onWheelDeltaChangedSubject = new Subject<number>();

  constructor() {
    this.setSize();
    this.scrollYChangedSubject.next();
    this.onWheelDeltaChangedSubject.next();
  }

  public get scroll$(): Observable<number> {
    return this.scrollYChangedSubject.asObservable();
  }

  public setSize() {
    this.winWidth = window.innerWidth;
    this.winHeight = window.innerHeight;
  }

  public onScroll() {
    this.scrollTop = document.documentElement.scrollTop;
    this.scrollYChangedSubject.next(this.scrollTop);
  }
}
