import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class CurrencyService {
  private currencySubject = new BehaviorSubject<string>(
    this.getCurrencyFromLocalStorage()
  );
  public currency$ = this.currencySubject.asObservable();

  constructor(private readonly storageService: LocalStorageService) {}

  private getCurrencyFromLocalStorage(): string {
    return this.storageService.currency ? this.storageService.currency : 'EUR';
  }

  public setCurrency(currencyCode: string): void {
    this.storageService.currency = currencyCode;
    this.currencySubject.next(currencyCode);
  }

  public getCurrency(): Observable<string> {
    return this.currency$;
  }
}
