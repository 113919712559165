import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { timer } from 'rxjs';
import { LocalNotificationService } from './local-notification.service';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  private expTimeOut: any;

  constructor(
    private readonly storageService: LocalStorageService,
    private readonly localNotificationService: LocalNotificationService
  ) {}

  public runSessionExpTimer(token: string) {
    this.stopSessionExpTimer();

    const tokenData = JSON.parse(atob(token.split('.')[1]));
    const exp = new Date(tokenData.exp * 1000);
    const sessionTimer = timer(exp);

    this.storageService.expiration = (tokenData.exp * 1000).toString();

    sessionTimer.subscribe(() => {
      this.localNotificationService.showNotification(
        'shared.local-notifications.expired',
        'toast-info'
      );
      this.storageService.logout();
    });
  }

  private stopSessionExpTimer() {
    if (this.expTimeOut) {
      clearTimeout(this.expTimeOut);
    }
  }

  public isAuthenticated() {
    const storedExpirationTime = this.storageService.expiration;
    if (storedExpirationTime) {
      const expirationTime = parseInt(storedExpirationTime, 10);
      const currentTime = new Date().getTime();

      if (currentTime >= expirationTime) {
        this.localNotificationService.showNotification(
          'shared.local-notifications.expired',
          'toast-info'
        );
        this.storageService.logout();
      } else {
        const sessionTimer = timer(expirationTime - currentTime);
        sessionTimer.subscribe(() => {
          this.localNotificationService.showNotification(
            'shared.local-notifications.expired',
            'toast-info'
          );
          this.storageService.logout();
        });
      }
    }
  }
}
