<div class="manual-form">
  <div class="manual-form__corner-wrapper">
    <div class="manual-form__wrapper">
      <div class="manual-form__title">
        {{ "components.manual-form.title" | translate }}
      </div>

      <div class="manual-form__text">
        {{ "components.manual-form.description" | translate }}
      </div>

      <form [formGroup]="form">
        <div class="manual-form__form-control">
          <ng-select
            [searchable]="false"
            [clearable]="false"
            [items]="brands"
            (change)="onChangeSelect($event)"
            formControlName="brand"
            appearance="outline"
            placeholder="{{ 'shared.placeholders.brand' | translate }}"
            id="brand"
          >
            <ng-template ng-label-tmp ng-option-tmp let-item="item">
              {{ item.name }}
            </ng-template>
          </ng-select>
        </div>

        <div class="manual-form__form-control">
          <ng-select
            [items]="models"
            [searchable]="false"
            [clearable]="false"
            [readonly]="isSelectDisabled"
            formControlName="model"
            appearance="outline"
            placeholder="{{ 'shared.placeholders.model' | translate }}"
            id="model"
          >
            <ng-template ng-label-tmp ng-option-tmp let-item="item">
              {{ item.name }}
            </ng-template>
          </ng-select>
        </div>

        <button
          class="manual-form__submit"
          [disabled]="form.invalid"
          (click)="submit()"
        >
          <span *ngIf="!isLoading">{{
            "shared.buttons.manual-button" | translate
          }}</span>
          <app-loader *ngIf="isLoading"></app-loader>
        </button>
      </form>
    </div>
  </div>
</div>
