import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { homeContent } from '@core/models/common';
import { CartService } from '@core/services/cart.service';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
})
export class HomePageComponent {
  public readonly content = homeContent;

  constructor(
    private readonly cartService: CartService,
    private readonly router: Router
  ) {
    if (this.router.url === '/order-success') {
      this.cartService.setCartCount(0);
    }
  }

  public scrollToManual() {
    window.scrollTo({
      left: 0,
      top: document.getElementById('manual').offsetTop,
      behavior: 'smooth',
    });
  }

  public get locale() {
    return `?locale=${localStorage.language}`;
  }
}
