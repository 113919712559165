import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ILink } from '@core/interfaces';
import { menuLinks } from '@core/models/layouts';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
})
export class NavigationComponent {
  @Output() private readonly linkClick = new EventEmitter<boolean>();
  public readonly menuLinks: ILink[] = menuLinks;
  constructor(private router: Router) {}

  onClick() {
    this.linkClick.next();
  }
  logoClick() {
    this.router.navigate(['']);
  }
}
