<div [ngSwitch]="type" class="currency-select">
  <ng-select
    *ngSwitchCase="'lang'"
    [formControl]="language"
    [items]="languageList"
    [searchable]="false"
    [clearable]="false"
    placeholder=""
    id="language"
    appearance="outline"
    class="mobile-select"
  >
    <ng-template ng-label-tmp let-item="item">
      <img
        src="assets/icons/local-flags/{{ item.label.toUpperCase() }}.svg"
        alt="{{ item.label.toUpperCase() }}"
        class="language-icon"
      />
      {{ item.label.toUpperCase() }}
    </ng-template>
    <ng-template ng-option-tmp let-item="item">
      {{ item.label.toUpperCase() }}
    </ng-template>
  </ng-select>

  <ng-select
    *ngSwitchDefault
    [formControl]="currency"
    [items]="currencyList"
    [searchable]="false"
    [clearable]="false"
    placeholder=""
    id="currency"
    appearance="outline"
    class="mobile-select"
  >
    <ng-template ng-label-tmp ng-option-tmp let-item="item">
      {{ item.label.toUpperCase() }}
    </ng-template>
  </ng-select>
</div>
