<div class="policies">
  <img
    src="/assets/images/contact-background.svg"
    alt=""
    class="policies__background"
  />

  <h2 class="main-title">
    {{ "pages.terms-and-conditions.text-1" | translate }}
  </h2>
  <p class="centered-text">
    {{ "pages.terms-and-conditions.text-2" | translate }}
    <a href="https://guideassist.com.ua">https://guideassist.com.ua</a>
    {{ "pages.terms-and-conditions.text-3" | translate }}
    <br /><br />
    {{ "pages.terms-and-conditions.text-4" | translate }}
  </p>

  <div class="title">{{ "pages.terms-and-conditions.text-5" | translate }}</div>

  <p>
    {{ "pages.terms-and-conditions.text-6" | translate }}
    <a href="https://guideassist.com.ua">https://guideassist.com.ua</a>
    {{ "pages.terms-and-conditions.text-7" | translate }} <br /><br />
    {{ "pages.terms-and-conditions.text-8" | translate }}
    <strong>{{ "pages.terms-and-conditions.text-9" | translate }}</strong>
    {{ "pages.terms-and-conditions.text-10" | translate }}<br /><br />
    {{ "pages.terms-and-conditions.text-11" | translate }}
  </p>

  <div class="title">
    {{ "pages.terms-and-conditions.text-12" | translate }}
  </div>

  <p class="list">
    {{ "pages.terms-and-conditions.text-13" | translate }}<br />
    ● <strong>{{ "pages.terms-and-conditions.text-6" | translate }}</strong>
    {{ "pages.terms-and-conditions.text-14" | translate }}
    <a href="https://guideassist.com.ua">https://guideassist.com.ua</a>
    {{ "pages.terms-and-conditions.text-15" | translate }}
    <strong>{{ "pages.terms-and-conditions.text-9" | translate }}.</strong
    ><br />
    ● <strong>{{ "pages.terms-and-conditions.text-16" | translate }}</strong>
    {{ "pages.terms-and-conditions.text-17" | translate }}<br />
    ● <strong>{{ "pages.terms-and-conditions.text-18" | translate }}</strong>
    {{ "pages.terms-and-conditions.text-19" | translate }}<br />
    ● <strong>{{ "pages.terms-and-conditions.text-20" | translate }}</strong>
    {{ "pages.terms-and-conditions.text-21" | translate }}<br />
    ● <strong>{{ "pages.terms-and-conditions.text-22" | translate }}</strong>
    {{ "pages.terms-and-conditions.text-23" | translate }}<br />
    ● <strong>{{ "pages.terms-and-conditions.text-24" | translate }}</strong>
    {{ "pages.terms-and-conditions.text-25" | translate }}<br />
    ● <strong>{{ "pages.terms-and-conditions.text-26" | translate }}</strong>
    {{ "pages.terms-and-conditions.text-27" | translate }}<br />
    ● <strong>{{ "pages.terms-and-conditions.text-28" | translate }}</strong>
    {{ "pages.terms-and-conditions.text-29" | translate }}<br />
  </p>

  <div class="title">
    {{ "pages.terms-and-conditions.text-30" | translate }}
  </div>

  <p class="list">
    {{ "pages.terms-and-conditions.text-31" | translate }}<br />
    {{ "pages.terms-and-conditions.text-32" | translate }}<br />
    ● {{ "pages.terms-and-conditions.text-33" | translate }} <br />
    ● {{ "pages.terms-and-conditions.text-34" | translate }} <br />
    ● {{ "pages.terms-and-conditions.text-35" | translate }} <br />
    {{ "pages.terms-and-conditions.text-36" | translate }}<br /><br />

    <strong>{{ "pages.terms-and-conditions.text-37" | translate }}</strong
    ><br />
    {{ "pages.terms-and-conditions.text-38" | translate }}<br />
    ● {{ "pages.terms-and-conditions.text-39" | translate }} <br />
    ● {{ "pages.terms-and-conditions.text-40" | translate }} <br />
    ● {{ "pages.terms-and-conditions.text-41" | translate }} <br />
    ● {{ "pages.terms-and-conditions.text-42" | translate }} <br />
    {{ "pages.terms-and-conditions.text-43" | translate }}<br /><br />

    <strong>{{ "pages.terms-and-conditions.text-44" | translate }}</strong
    ><br />
    {{ "pages.terms-and-conditions.text-6" | translate }}
    <a href="https://guideassist.com.ua">https://guideassist.com.ua</a>
    {{ "pages.terms-and-conditions.text-45" | translate }}<br />
    ● {{ "pages.terms-and-conditions.text-46" | translate }} <br />
    ● {{ "pages.terms-and-conditions.text-47" | translate }} <br />
    ● {{ "pages.terms-and-conditions.text-48" | translate }} <br /><br />
    <strong>{{ "pages.terms-and-conditions.text-49" | translate }}</strong
    ><br />
    {{ "pages.terms-and-conditions.text-50" | translate }} <br />
    {{ "pages.terms-and-conditions.text-51" | translate }} <br />
    {{ "pages.terms-and-conditions.text-52" | translate }}
  </p>

  <div class="title">
    {{ "pages.terms-and-conditions.text-53" | translate }}
  </div>

  <p class="list">
    <strong>{{ "pages.terms-and-conditions.text-54" | translate }}</strong
    ><br />
    {{ "pages.terms-and-conditions.text-55" | translate }} <br />
    <strong>{{ "pages.terms-and-conditions.text-56" | translate }}</strong
    ><br />
    {{ "pages.terms-and-conditions.text-57" | translate }} <br />
    ● {{ "pages.terms-and-conditions.text-58" | translate }} <br />
    ● {{ "pages.terms-and-conditions.text-59" | translate }} <br />
    <strong>{{ "pages.terms-and-conditions.text-60" | translate }}</strong
    ><br />
    {{ "pages.terms-and-conditions.text-61" | translate }} <br />
    <strong>{{ "pages.terms-and-conditions.text-62" | translate }}</strong
    ><br />
    {{ "pages.terms-and-conditions.text-63" | translate }} <br />
    ● {{ "pages.terms-and-conditions.text-64" | translate }} <br />
    ● {{ "pages.terms-and-conditions.text-65" | translate }} <br />
    ● {{ "pages.terms-and-conditions.text-66" | translate }} <br />
    <strong>{{ "pages.terms-and-conditions.text-67" | translate }}</strong
    ><br />
    {{ "pages.terms-and-conditions.text-68" | translate }} <br />
    <strong>{{ "pages.terms-and-conditions.text-69" | translate }}</strong
    ><br />
    {{ "pages.terms-and-conditions.text-70" | translate }} <br />
    ● {{ "pages.terms-and-conditions.text-71" | translate }} <br />
    ● {{ "pages.terms-and-conditions.text-72" | translate }} <br />
    &nbsp; - {{ "pages.terms-and-conditions.text-73" | translate }} <br />
    &nbsp; - {{ "pages.terms-and-conditions.text-74" | translate }} <br />
    &nbsp; - {{ "pages.terms-and-conditions.text-75" | translate }} <br />
    <strong>{{ "pages.terms-and-conditions.text-76" | translate }}</strong
    ><br />
    {{ "pages.terms-and-conditions.text-77" | translate }} <br />
    <strong>{{ "pages.terms-and-conditions.text-78" | translate }}</strong
    ><br />
    {{ "pages.terms-and-conditions.text-79" | translate }}
    <a href="mailto:support@guideassist.com.ua">support@guideassist.com.ua</a>.
  </p>

  <div class="title">
    {{ "pages.terms-and-conditions.text-80" | translate }}
  </div>

  <p class="list">
    <strong>{{ "pages.terms-and-conditions.text-81" | translate }}</strong
    ><br />
    {{ "pages.terms-and-conditions.text-82" | translate }}<br />
    ● {{ "pages.terms-and-conditions.text-83" | translate }} <br />
    ● {{ "pages.terms-and-conditions.text-84" | translate }} <br />
    <strong>{{ "pages.terms-and-conditions.text-85" | translate }}</strong
    ><br />
    {{ "pages.terms-and-conditions.text-86" | translate }}<br />
    ● {{ "pages.terms-and-conditions.text-87" | translate }} <br />
    ● {{ "pages.terms-and-conditions.text-88" | translate }} <br />
    ● {{ "pages.terms-and-conditions.text-89" | translate }} <br />
    <strong>{{ "pages.terms-and-conditions.text-90" | translate }}</strong
    ><br />
    {{ "pages.terms-and-conditions.text-91" | translate }}
    <a href="mailto:support@guideassist.com.ua">support@guideassist.com.ua</a>
    {{ "pages.terms-and-conditions.text-92" | translate }} <br />
    ● {{ "pages.terms-and-conditions.text-93" | translate }} <br />
    ● {{ "pages.terms-and-conditions.text-94" | translate }} <br />
    <strong>{{ "pages.terms-and-conditions.text-95" | translate }}</strong
    ><br />
    {{ "pages.terms-and-conditions.text-96" | translate }}
  </p>

  <div class="title">
    {{ "pages.terms-and-conditions.text-97" | translate }}
  </div>

  <p class="list">
    <strong>{{ "pages.terms-and-conditions.text-98" | translate }}</strong
    ><br />
    {{ "pages.terms-and-conditions.text-99" | translate }}<br />
    ● {{ "pages.terms-and-conditions.text-100" | translate }} <br />
    ● {{ "pages.terms-and-conditions.text-101" | translate }} <br />
    ● {{ "pages.terms-and-conditions.text-102" | translate }} <br />

    <strong>{{ "pages.terms-and-conditions.text-103" | translate }}</strong
    ><br />
    {{ "pages.terms-and-conditions.text-104" | translate }}<br />
    ● {{ "pages.terms-and-conditions.text-105" | translate }} <br />
    ● {{ "pages.terms-and-conditions.text-106" | translate }} <br />
    ● {{ "pages.terms-and-conditions.text-107" | translate }} <br />

    <strong>{{ "pages.terms-and-conditions.text-108" | translate }}</strong
    ><br />
    {{ "pages.terms-and-conditions.text-109" | translate }}<br />

    ● {{ "pages.terms-and-conditions.text-110" | translate }}
    <a href="mailto:support@guideassist.com.ua">support@guideassist.com.ua</a
    >.<br />
    ● {{ "pages.terms-and-conditions.text-111" | translate }} <br />
    &nbsp; - {{ "pages.terms-and-conditions.text-112" | translate }} <br />
    &nbsp; - {{ "pages.terms-and-conditions.text-113" | translate }} <br />
    &nbsp; - {{ "pages.terms-and-conditions.text-114" | translate }} <br />
    <strong>{{ "pages.terms-and-conditions.text-115" | translate }}</strong
    ><br />
    ● {{ "pages.terms-and-conditions.text-116" | translate }} <br />
    ● {{ "pages.terms-and-conditions.text-117" | translate }} <br />

    <strong>{{ "pages.terms-and-conditions.text-118" | translate }}</strong
    ><br />
    {{ "pages.terms-and-conditions.text-119" | translate }}<br />
    ● {{ "pages.terms-and-conditions.text-120" | translate }} <br />
    ● {{ "pages.terms-and-conditions.text-121" | translate }} <br />
    ● {{ "pages.terms-and-conditions.text-122" | translate }} <br />
  </p>

  <div class="title">
    {{ "pages.terms-and-conditions.text-123" | translate }}
  </div>

  <p class="list">
    {{ "pages.terms-and-conditions.text-124" | translate }}<br />
    <strong>{{ "pages.terms-and-conditions.text-125" | translate }}</strong
    ><br />
    ● {{ "pages.terms-and-conditions.text-126" | translate }} <br />
    ● {{ "pages.terms-and-conditions.text-127" | translate }} <br />
    ● {{ "pages.terms-and-conditions.text-128" | translate }} <br />
    ● {{ "pages.terms-and-conditions.text-129" | translate }} <br />
  </p>

  <div class="title">
    {{ "pages.terms-and-conditions.text-130" | translate }}
  </div>

  <p class="list">
    {{ "pages.terms-and-conditions.text-131" | translate }}<br />
    {{ "pages.terms-and-conditions.text-132" | translate }}<br />
    {{ "pages.terms-and-conditions.text-133" | translate }}<br />
    ● {{ "pages.terms-and-conditions.text-134" | translate }} <br />
    ● {{ "pages.terms-and-conditions.text-135" | translate }} <br />
    ● {{ "pages.terms-and-conditions.text-136" | translate }} <br />
  </p>

  <div class="title">
    {{ "pages.terms-and-conditions.text-137" | translate }}
  </div>

  <p>
    {{ "pages.terms-and-conditions.text-138" | translate }}<br /><br />
    {{ "pages.terms-and-conditions.text-139" | translate }}<br /><br />
    {{ "pages.terms-and-conditions.text-140" | translate }}
  </p>

  <div class="title">
    {{ "pages.terms-and-conditions.text-141" | translate }}
  </div>

  <p>
    {{ "pages.terms-and-conditions.text-142" | translate }}
  </p>

  <div class="title">
    {{ "pages.terms-and-conditions.text-143" | translate }}
  </div>

  <p>
    {{ "pages.terms-and-conditions.text-144" | translate }}<br /><br />
    {{ "pages.terms-and-conditions.text-145" | translate }}<br /><br />
    {{ "pages.terms-and-conditions.text-146" | translate }}<br /><br />
    {{ "pages.terms-and-conditions.text-147" | translate }}
  </p>

  <div class="title">
    {{ "pages.terms-and-conditions.text-148" | translate }}
  </div>

  <p class="list">
    {{ "pages.terms-and-conditions.text-149" | translate }}<br />
    <strong>{{ "pages.terms-and-conditions.text-150" | translate }}</strong
    ><br />
    {{ "pages.terms-and-conditions.text-151" | translate }}<br />
    ● {{ "pages.terms-and-conditions.text-152" | translate }} <br />
    ● {{ "pages.terms-and-conditions.text-153" | translate }} <br />
    ● {{ "pages.terms-and-conditions.text-154" | translate }} <br />
    {{ "pages.terms-and-conditions.text-155" | translate }}<br />
    {{ "pages.terms-and-conditions.text-156" | translate }}<br />

    <strong>{{ "pages.terms-and-conditions.text-157" | translate }}</strong
    ><br />
    {{ "pages.terms-and-conditions.text-158" | translate }}<br />
    ● {{ "pages.terms-and-conditions.text-159" | translate }} <br />
    ● {{ "pages.terms-and-conditions.text-160" | translate }} <br />
    ● {{ "pages.terms-and-conditions.text-161" | translate }} <br />
    {{ "pages.terms-and-conditions.text-162" | translate }}
  </p>

  <div class="title">
    {{ "pages.terms-and-conditions.text-163" | translate }}
  </div>

  <p>
    {{ "pages.terms-and-conditions.text-164" | translate }}<br />
    {{ "pages.terms-and-conditions.text-165" | translate }}
  </p>

  <div class="title">
    {{ "pages.terms-and-conditions.text-166" | translate }}
  </div>

  <p>
    {{ "pages.terms-and-conditions.text-167" | translate }}<br /><br />
    {{ "pages.terms-and-conditions.text-168" | translate }}<br /><br />
    {{ "pages.terms-and-conditions.text-169" | translate }}
  </p>

  <div class="title">
    {{ "pages.terms-and-conditions.text-170" | translate }}
  </div>

  <p>
    {{ "pages.terms-and-conditions.text-171" | translate }}<br />
    <strong>{{ "pages.terms-and-conditions.text-172" | translate }}</strong
    ><br />
    {{ "pages.terms-and-conditions.text-173" | translate }}<br />
  </p>

  <div class="title">
    {{ "pages.terms-and-conditions.text-174" | translate }}
  </div>

  <p>
    {{ "pages.terms-and-conditions.text-175" | translate }}
    <a href="mailto:support@guideassist.com.ua">support@guideassist.com.ua</a>
  </p>
</div>
