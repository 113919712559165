<section class="reviews-slider">
  <div class="reviews-slider__wrapper">
    <div class="reviews-slider__title">
      {{ "pages.home.title-10" | translate }}
    </div>

    <ngx-slick-carousel class="reviews-slider__slider" [config]="sliderConfig">
      <div
        ngxSlickItem
        class="reviews-slider__slider-item"
        *ngFor="let item of sliderItems"
      >
        <div class="reviews-slider__content-wrapper">
          <img
            class="reviews-slider__inner-image"
            src="/assets/images/{{ item.iconName }}"
            alt="Icon"
          />

          <h5 class="reviews-slider__inner-title">{{ item.title }}</h5>

          <p class="reviews-slider__inner-text">
            {{ "pages.home." + item.value | translate }}
          </p>
        </div>
      </div>
    </ngx-slick-carousel>
  </div>
</section>
