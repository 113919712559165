import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  private readonly localStorageKey = 'cartCount';
  private cartCountSubject: BehaviorSubject<string>;

  constructor(private readonly apiService: ApiService) {
    const storedCount = this.loadCartFromLocalStorage();
    this.cartCountSubject = new BehaviorSubject<string>(storedCount);
  }

  // Get the observable for cart items
  getCartCount() {
    return this.cartCountSubject.asObservable();
  }

  // Add an item to the cart
  setCartCount(count?: number): void {
    const currentItems = Number(this.cartCountSubject.value) || 0;
    if (count !== undefined || count === 0) {
      this.cartCountSubject.next(count.toString());
      this.saveCartToLocalStorage(JSON.stringify(count));
    } else {
      const newCount = currentItems + 1;
      this.cartCountSubject.next(newCount.toString());
      this.saveCartToLocalStorage(JSON.stringify(newCount));
    }
  }

  // Load cart items from local storage
  private loadCartFromLocalStorage(): string {
    const storedItems = localStorage.getItem(this.localStorageKey);
    return storedItems ? JSON.parse(storedItems) : 0;
  }

  // Save the cart items back to local storage
  private saveCartToLocalStorage(cartCount: string): void {
    localStorage.setItem(this.localStorageKey, cartCount);
  }

  public getCart() {
    return this.apiService.executeRequest('get', `/cart`, {});
  }

  public addModel(model: string) {
    return this.apiService.executeRequest('post', `/cart`, { model });
  }

  public removeCartItem(id: string) {
    return this.apiService.executeRequest('delete', `/cart/${id}`, {});
  }
}
