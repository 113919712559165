import { NgModule } from '@angular/core';
import { SHARED_PIPES } from '../core/pipes';
import { COMPONENTS } from '../components';
import { SHARED_DIRECTIVES } from '../core/directives';
import { SHARED_MODULES } from './modules';

@NgModule({
  declarations: [...COMPONENTS, ...SHARED_DIRECTIVES, ...SHARED_PIPES],
  imports: [...SHARED_MODULES],
  exports: [
    ...COMPONENTS,
    ...SHARED_DIRECTIVES,
    ...SHARED_PIPES,
    ...SHARED_MODULES,
  ],
})
export class SharedModule {}
