import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ScrollService } from 'src/app/core/services/scroll.service';
import { unsubscribe } from '@core/helpers/unsubscribe';
import { scrollTop } from '@core/helpers/scroll-top';
import { NavigationEnd, Router } from '@angular/router';
import { LocalStorageService } from '@core/services/local-storage.service';
import { CartService } from '@core/services/cart.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  public textColor: 'white' | 'dark' = 'white';
  public isMenuOpened = false;
  public isScrolled = false;
  private lastScrollOffset: number = 0;
  public isScrolledDown = false;
  public subs: Subscription[] = [];
  public darkHeaderPages: string[] = [
    '/prices',
    '/account',
    '/terms-and-conditions',
    '/privacy-policy',
    '/cookie-policy',
  ];
  public cartCount: number;
  public isMenuOpen;

  public get isAuthorized() {
    return this.storageService.token ? true : false;
  }

  constructor(
    private readonly cartService: CartService,
    private readonly scrollService: ScrollService,
    private readonly storageService: LocalStorageService,
    private readonly router: Router
  ) {}

  ngOnInit() {
    this.cartService
      .getCartCount()
      .subscribe((res) => (this.cartCount = Number(res)));
    this.subs.push(
      this.scrollService.scroll$.subscribe((scroll) => {
        this.isScrolled = scroll > 0;
        this.isScrolledDown = this.lastScrollOffset < scroll;
        this.lastScrollOffset = scroll;
      })
    );

    this.checkRouteForTextColor();
  }

  ngOnDestroy() {
    unsubscribe(this.subs);
  }

  public openMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  public closeMenu() {
    this.isMenuOpen = false;
  }

  public logoClick() {
    scrollTop();
  }

  private checkRouteForTextColor() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.changeTextColor(event.url);
      }
    });
  }

  private changeTextColor(pageName: string) {
    this.darkHeaderPages.includes(pageName)
      ? (this.textColor = 'dark')
      : (this.textColor = 'white');
  }

  public openCart() {
    this.router.navigate(['/cart']);
  }

  public logout() {
    this.storageService.logout();
    this.cartService.setCartCount(0);
  }
}
