import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { scrollTop } from '@core/helpers//scroll-top';
import { MenuService } from '@core/services/menu.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styles: [],
})
export class FooterComponent {
  public readonly date: Date = new Date();

  constructor(
    private readonly menuService: MenuService,
    private readonly router: Router
  ) {}

  public logoClick(): void {
    this.menuService.close();
    if (this.router.url === '/') {
      scrollTop();
    } else {
      this.router.navigateByUrl('/');
    }
  }
}
