import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

export interface IUserData {
  id: string;
  username: string;
  email: string;
  name: string;
}

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  private userData: IUserData;

  setUserData(data: IUserData) {
    this.userData = data;
  }

  getUserData(): IUserData {
    return this.userData;
  }

  constructor(private readonly apiService: ApiService) {}

  getBrands() {
    return this.apiService.executeRequest('get', `/guides/brands`, {});
  }

  getModels(brandId: string) {
    return this.apiService.executeRequest(
      'get',
      `/guides/models/brand/${brandId}`,
      {}
    );
  }
}
