import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  public isOpen = false;

  public readonly menuChangedSubject = new Subject<boolean>();

  public readonly closeSubmenuSubject$ = new Subject();

  public get onMenuChanges$(): Observable<boolean> {
    return this.menuChangedSubject.asObservable();
  }

  constructor() {
    this.close();
  }

  public toggle(): void {
    this.isOpen = !this.isOpen;

    this.menuChangedSubject.next(this.isOpen);
  }

  public close(): void {
    this.isOpen = false;

    this.menuChangedSubject.next(this.isOpen);
  }
}
