import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { forkJoin, of } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';

import { CartService } from '@core/services/cart.service';
import { LocalNotificationService } from '@core/services/local-notification.service';
import { LocalStorageService } from '@core/services/local-storage.service';
import { ProductsService } from '@core/services/products.service';

@Component({
  selector: 'app-manual-form',
  templateUrl: './manual-form.component.html',
})
export class ManualFormComponent implements OnInit {
  public form: FormGroup;
  public isLoading: boolean;
  public isSelectDisabled: boolean = true;

  public brands = [];
  public models = [];

  public get isAuthorized() {
    return this.storageService.token ? true : false;
  }

  constructor(
    private readonly productsService: ProductsService,
    private readonly cartService: CartService,
    private readonly localNotificationService: LocalNotificationService,
    private readonly storageService: LocalStorageService,
    private readonly router: Router
  ) {}

  ngOnInit() {
    this.initForms();
    this.productsService.getBrands().subscribe((res) => {
      this.brands = res;
    });

    this.cartService.getCartCount().subscribe(() => {
      this.form.get('brand').patchValue(null);
      this.form.get('model').patchValue(null);
    });
  }

  onChangeSelect(event) {
    this.isSelectDisabled = false;
    this.form.get('model').patchValue(null);

    return forkJoin({
      models: this.productsService.getModels(event.id),
      cart: this.isAuthorized ? this.cartService.getCart() : of({ cart: [] }),
    })
      .pipe(
        map(
          ({ models, cart }) =>
            (this.models = this.filterByModelIds(models, cart?.cart))
        )
      )
      .subscribe();
  }

  private filterByModelIds(models: any[], cartModel: any[]): any[] {
    const modelIds = cartModel.map((model) => model.model.id);
    return models.filter((model) => !modelIds.includes(model.id));
  }

  public initForms() {
    this.form = new FormGroup({
      brand: new FormControl(null, Validators.required),
      model: new FormControl(null, [
        Validators.required,
        Validators.maxLength(50),
      ]),
    });
  }

  public submit() {
    if (this.isAuthorized) {
      this.isLoading = true;
      this.cartService
        .addModel(this.form.get('model').value.id)
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe(
          () => {
            this.cartService.setCartCount();
            this.localNotificationService.showNotification(
              'shared.local-notifications.add-cart-message',
              'toast-success',
              true,
              'shared.local-notifications.add-cart-title'
            );
          },
          (error) =>
            this.localNotificationService.showNotification(
              error?.error?.message,
              'toast-error'
            )
        );
    } else {
      this.localNotificationService.showNotification(
        'shared.local-notifications.need-auth',
        'toast-info'
      );
      this.router.navigate(['/account']);
    }
  }
}
