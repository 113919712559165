import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CurrencyService } from '@core/services/currency.service';

@Component({
  selector: 'currency-icon',
  template: `
    <span *ngIf="currencyCode">
      {{ getCurrencyIcon(currencyCode) }}
    </span>
  `,
  styles: [
    // Add your styles here
  ],
})
export class CurrencyIconComponent implements OnInit, OnDestroy {
  @Input() currencyCode: string; // Added Input property
  private subscription: Subscription;

  constructor(private currencyService: CurrencyService) {}

  ngOnInit(): void {
    if (!this.currencyCode) {
      this.subscription = this.currencyService.currency$.subscribe(
        (currency) => {
          this.currencyCode = currency;
        }
      );
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  getCurrencyIcon(currency: string): string {
    const currencyIcons: { [key: string]: string } = {
      EUR: '€',
      USD: '$',
      UAH: '₴',
    };
    return currencyIcons[currency] || currency;
  }
}
