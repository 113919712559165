import {
  Directive,
  ElementRef,
  Input,
  EventEmitter,
  AfterViewInit,
} from '@angular/core';

@Directive({
  selector: '[tooglePass]',
})
export class TooglePasswordDirective implements AfterViewInit {
  @Input('tooglePassTriger')
  public trigger: EventEmitter<any>;

  constructor(public elementRef: ElementRef) {}

  ngAfterViewInit(): void {
    if (this.trigger !== null) {
      this.trigger.subscribe((id: string) => {
        const icon = document.getElementById(id);
        const input = document.getElementById(`${id}Input`);
        const inputType = input?.getAttribute('type');

        if (input) {
          if (inputType === 'password') {
            icon?.setAttribute('src', `/assets/icons/hide-password-icon.svg`);
            input.setAttribute('type', 'text');
          } else {
            icon?.setAttribute('src', `/assets/icons/show-password-icon.svg`);
            input.setAttribute('type', 'password');
          }
        } else {
          console.error('missing HTMLElement');
          return;
        }
      });
    }
  }
}
