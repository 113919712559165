import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  /* keys */
  private readonly tokenKey = 'token';
  private readonly emailKey = 'email';
  private readonly expirationKey = 'expiration';
  private readonly currencyKey = 'currency';
  private readonly languageKey = 'language';

  /* cached data */
  private cachedToken: string | null = null;
  private cachedEmail: string | null = null;
  private cachedExpiration: string | null = null;
  private cachedCurrency: string | null = null;
  private cachedLanguage: string | null = null;

  /* storage */
  private readonly storage = {
    [this.tokenKey]: this.cachedToken,
    [this.emailKey]: this.cachedEmail,
    [this.expirationKey]: this.cachedExpiration,
    [this.currencyKey]: this.cachedCurrency,
    [this.languageKey]: this.cachedLanguage,
  };

  public get token(): string {
    return this.getByKey(this.tokenKey);
  }

  public set token(token: string) {
    this.setByKey(this.tokenKey, token);
  }

  public get email(): string {
    return this.getByKey(this.emailKey);
  }

  public set email(email: string) {
    this.setByKey(this.emailKey, email);
  }

  public get expiration(): string {
    return this.getByKey(this.expirationKey);
  }

  public set expiration(expiration: string) {
    this.setByKey(this.expirationKey, expiration);
  }

  public get currency(): string {
    return this.getByKey(this.currencyKey);
  }

  public set currency(currency: string) {
    this.setByKey(this.currencyKey, currency);
  }

  public get language(): string {
    return this.getByKey(this.languageKey);
  }

  public set language(language: string) {
    this.setByKey(this.languageKey, language);
  }

  constructor(private router: Router) {
    this.cachedToken = localStorage.getItem(this.tokenKey);
    this.cachedEmail = localStorage.getItem(this.emailKey);
    this.cachedExpiration = localStorage.getItem(this.expirationKey);
  }

  public clearToken() {
    this.clearByKey(this.tokenKey);
  }

  public clearEmail() {
    this.clearByKey(this.emailKey);
  }

  public clearExpiration() {
    this.clearByKey(this.expirationKey);
  }

  public logout() {
    this.clearToken();
    this.clearEmail();
    this.clearExpiration();

    this.router.navigate(['/']);
  }

  /* private helpers */

  private clearByKey(key: string) {
    localStorage.removeItem(key);
    this.storage[key] = null;
  }

  private getByKey(key: string): string {
    if (!this.storage[key]) {
      this.storage[key] = localStorage.getItem(key);
    }

    return this.storage[key];
  }

  private setByKey(key: string, value: string) {
    localStorage.setItem(key, value);
    this.storage[key] = value;
  }
}
