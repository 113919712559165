<footer class="footer">
  <div class="footer__wrapper">
    <div class="footer__top">
      <a routerLink="/terms-and-conditions" class="footer__link">{{
        "footer.link" | translate
      }}</a>
      <!-- <a routerLink="/account" class="footer__link">{{
        "footer.link-1" | translate
      }}</a> -->
      <a routerLink="/subscription-terms-and-conditions" class="footer__link">{{
        "footer.link-2" | translate
      }}</a>
      <a routerLink="/contact" class="footer__link">{{
        "footer.link-3" | translate
      }}</a>
      <a routerLink="/privacy-policy" class="footer__link">{{
        "footer.link-4" | translate
      }}</a>
    </div>

    <div class="footer__bottom">
      <div class="footer__contacts">
        <a
          href="mailto:support@guideassist.com.ua"
          class="footer__contacts-title"
          >{{ "footer.link-5" | translate }}</a
        >

        <div class="footer__contact-info">
          support@guideassist.com.ua <br />
          {{ "footer.link-6" | translate }}
        </div>
      </div>

      <div class="footer__contacts">
        <a href="tel:+380800358942" class="footer__contacts-title">{{
          "footer.link-7" | translate
        }}</a>

        <div class="footer__contact-info">
          +380800358942 <br />
          <!-- {{ "footer.link-8" | translate }} -->
        </div>
      </div>

      <div class="footer__additional-info">
        <div class="footer__payments-wrapper">
          <img src="/assets/images/payment-methods.png" alt="Payment Methods" />
        </div>

        <div class="footer__contact-info">
          {{ "footer.link-9" | translate }}
        </div>
      </div>
    </div>
  </div>
</footer>
