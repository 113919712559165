import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
})
export class SliderComponent implements OnInit {
  public sliderConfig = {
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: true,
    prevArrow:
      '<button type="button" class="slider__prev"><img src="/assets/icons/arrow-left.svg"></img></button>',
    nextArrow:
      '<button type="button" class="slider__next"><img src="/assets/icons/arrow-right.svg"></img></button>',
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  public sliderItems = [
    {
      iconName: 'computer',
    },
    {
      iconName: 'mobile',
    },
    {
      iconName: 'tools',
    },
    {
      iconName: 'hygiene',
    },
    {
      iconName: 'appliances',
    },
    {
      iconName: 'video',
    },
  ];

  constructor() {}

  ngOnInit(): void {}
}
