import { ILink } from '@core/interfaces';

export const menuLinks: ILink[] = [
  {
    text: 'Prices',
    path: 'prices',
  },
  {
    text: 'Guarantees',
    path: 'guarantees',
  },
  {
    text: 'Place an order',
    path: 'place-an-order',
  },
  {
    text: 'About Us',
    path: 'about',
  },
  {
    text: 'FAQ',
    path: 'faq',
  },
  {
    text: 'Contact Us',
    path: 'contact',
  },
  {
    text: 'My account',
    path: 'account',
  },
];
