<div class="log-in-form">
  <div class="log-in-form__corner-wrapper">
    <div class="log-in-form__wrapper">
      <form [formGroup]="form">
        <div class="log-in-form__title">
          {{ "pages.auth.sign-up-title" | translate }}
        </div>

        <div class="log-in-form__text">
          {{ "pages.auth.sign-up-description" | translate }}
        </div>

        <div class="log-in-form__form-control">
          <input
            type="email"
            class="log-in-form__input"
            id="email"
            formControlName="email"
            placeholder="{{ 'shared.placeholders.email' | translate }}"
          />
        </div>

        <div class="log-in-form__checkbox-wrapper">
          <input
            type="checkbox"
            id="agreement"
            formControlName="agreement"
            class="log-in-form__checkbox"
          />

          <label for="agreement" class="log-in-form__checkbox-label">
            {{ "shared.legacy.legacy-text" | translate }}
            <a routerLink="/terms-and-conditions" target="_blank">{{
              "shared.legacy.legacy-link" | translate
            }}</a>
          </label>
        </div>

        <button
          class="log-in-form__submit"
          [disabled]="form.invalid || !agreement"
          (click)="submit()"
        >
          <span *ngIf="!isLoading">{{
            "shared.buttons.submit" | translate
          }}</span>
          <app-loader *ngIf="isLoading"></app-loader>
        </button>
      </form>
    </div>
  </div>
</div>
