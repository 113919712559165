import { Component, HostListener } from '@angular/core';
import { ScrollService } from '@core/services/scroll.service';
import { polyfill } from 'smoothscroll-polyfill';
import { SessionService } from '@core/services/session.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  constructor(
    private scrollService: ScrollService,
    private readonly sessionService: SessionService
  ) {
    polyfill();
    this.sessionService.isAuthenticated();
  }

  @HostListener('window:scroll', ['$event.target'])
  public onScroll() {
    this.scrollService.onScroll();
  }
}
