import { DynamicModule } from 'ng-dynamic-component';
import { NgSelectModule } from '@ng-select/ng-select';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { HttpLoaderFactory } from 'src/app/app.module';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

export const SHARED_MODULES = [
  CommonModule,
  SlickCarouselModule,
  TranslateModule,
  FormsModule,
  ReactiveFormsModule,
  ToastrModule.forRoot({
    closeButton: true,
  }),
  HttpClientModule,
  NgSelectModule,
  DynamicModule,
  RouterModule,
];
