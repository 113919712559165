<div
  [formGroup]="form"
  [ngSwitch]="conf?.type"
  class="form-field"
  *ngIf="!conf.noope"
>
  <div class="control-head" *ngIf="conf?.label">
    <label>{{ conf?.label | translate }}</label>
  </div>

  <textarea
    *ngSwitchCase="'textarea'"
    [placeholder]="conf?.placeholder | translate"
    [formControlName]="conf.name"
  ></textarea>

  <input
    *ngSwitchDefault
    [type]="conf?.type || 'text'"
    [placeholder]="conf?.placeholder | translate"
    [formControlName]="conf.name"
  />
</div>
