<main class="home">
  <section class="manual" id="manual">
    <img
      src="/assets/images/manual-background.svg"
      alt=""
      class="manual__background"
    />

    <div class="manual__content-wrapper">
      <div class="manual__flex-wrapper">
        <div class="manual__info-wrapper">
          <h3 class="manual__title">
            {{ "pages.home.title" | translate }}
            <span>{{ "pages.home.title-1" | translate }}</span
            >!
          </h3>

          <ul>
            <li>{{ "pages.home.list-item-1" | translate }}</li>
            <li>{{ "pages.home.list-item-2" | translate }}</li>
            <li>{{ "pages.home.list-item-3" | translate }}</li>
          </ul>
        </div>

        <app-manual-form></app-manual-form>
      </div>
    </div>
  </section>

  <section class="slider">
    <app-slider></app-slider>
  </section>

  <section class="why">
    <img
      src="/assets/images/benefits-background.svg"
      alt=""
      class="why__background"
    />

    <div class="why__wrapper">
      <div class="why__title">
        {{ "pages.home.title-3" | translate }} <span>GuideAssist</span>?
      </div>

      <div class="why__block">
        <div class="why__card-container">
          <div class="why__card">
            <div class="why__card-title">
              <span>{{ "pages.home.title-4" | translate }}</span>
              {{ "pages.home.title-5" | translate }}
            </div>

            <div class="why__card-text">
              {{ "pages.home.description-1" | translate }}
            </div>
          </div>
        </div>

        <div class="why__image-wrapper">
          <img src="/assets/images/why-1.png" alt="" class="why__img" />
        </div>
      </div>

      <div class="why__block reversed">
        <div class="why__card-container">
          <div class="why__card">
            <div class="why__card-title">
              <span>27.000+</span> {{ "pages.home.title-6" | translate }}
            </div>

            <div class="why__card-text">
              {{ "pages.home.description-2" | translate }}
            </div>
          </div>
        </div>

        <div class="why__image-wrapper">
          <img src="/assets/images/why-2.png" alt="" class="why__img" />
        </div>
      </div>

      <div class="why__block">
        <div class="why__card-container">
          <div class="why__card">
            <div class="why__card-title">
              <span>{{ "pages.home.title-7" | translate }}</span>
              {{ "pages.home.title-8" | translate }}
            </div>

            <div class="why__card-text">
              {{ "pages.home.description-3" | translate }}
            </div>
          </div>
        </div>

        <div class="why__image-wrapper">
          <img src="/assets/images/why-3.png" alt="" class="why__img" />
        </div>
      </div>
    </div>
  </section>

  <div class="info-text">
    <div class="info-text__wrapper">
      <div class="info-text__background-wrapper">
        <img
          src="/assets/images/instructions-background.svg"
          alt=""
          class="info__background-img"
        />

        <div class="info-text__content-wrapper">
          <div class="info-text__title">
            {{ "pages.home.title-9" | translate }}
          </div>

          <div class="info-text__text">
            {{ "pages.home.description-4" | translate }}
          </div>

          <button class="info-text__button btn" (click)="scrollToManual()">
            <span>{{ "shared.buttons.manual-button" | translate }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="home__background-wrapper">
    <img
      src="/assets/images/benefits-background.svg"
      alt=""
      class="home__benefits-background-img"
    />

    <!-- <section class="benefits">
      <div class="benefits__title">Our <span>PREMIUM</span> service</div>

      <div class="benefits__text">
        7 days of discovery at 0.95 USD for an unlimited amount of inquiries.
        <br />
        At the conclusion of your discovery period, your membership to our
        service is set up by default to renew itself automatically. Your credit
        card will be charged the sum of 46.95 USD every two months as part of
        your membership. Your subscription may be terminated at any point during
        its duration by disabling automatic renewal; this request will take
        effect at the end of the current subscription period. Please see our
        Terms and Conditions for further details.
      </div>

      <div class="benefits__cards-wrapper">
        <div class="benefits__card-container">
          <div class="benefits__corner-wrapper">
            <div class="benefits__card">
              <div class="benefits__card-number">1</div>

              <div class="benefits__card-info">
                <div class="benefits__card-title">Download</div>

                <div class="benefits__card-text">
                  After registering on our website, you will have unrestricted
                  access to request and download User Guides and Manuals.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="benefits__card-container">
          <div class="benefits__corner-wrapper">
            <div class="benefits__card">
              <div class="benefits__card-number">2</div>

              <div class="benefits__card-info">
                <div class="benefits__card-title">Storage</div>

                <div class="benefits__card-text">
                  You have access to your documents through your online
                  membership, which you can download at any time.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="benefits__card-container">
          <div class="benefits__corner-wrapper">
            <div class="benefits__card">
              <div class="benefits__card-number">3</div>

              <div class="benefits__card-info">
                <div class="benefits__card-title">Instant dispatch</div>

                <div class="benefits__card-text">
                  All of your requests and User Manuals will be reviewed once
                  you've registered, and they'll be provided to you via email as
                  soon as possible.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="benefits__card-container">
          <div class="benefits__corner-wrapper">
            <div class="benefits__card">
              <div class="benefits__card-number">4</div>

              <div class="benefits__card-info">
                <div class="benefits__card-title">Unlimited use</div>

                <div class="benefits__card-text">
                  The Premium subscription fee covers unlimited use of our
                  service! Enjoy endless requests for documents and user
                  manuals.
                  <br /><br />
                  Service: 0.95 GBP for 7 days then 36.95 GBP bi-monthly
                </div>
              </div>
            </div>
          </div>
        </div>

        <button class="benefits__button btn" (click)="scrollToManual()">
          <span>Get premium service</span>
        </button>
      </div>
    </section> -->

    <section>
      <app-reviews-slider></app-reviews-slider>
    </section>

    <section class="instructions">
      <div class="instructions__wrapper">
        <img
          src="/assets/images/instructions-background.svg"
          alt=""
          class="instructions__background-img"
        />

        <div class="instructions__left">
          <div class="instructions__title">
            {{ "pages.home.title-11" | translate }}
          </div>
          <div class="instructions__text">
            {{ "pages.home.list-item-4" | translate }}
            <br />
            <br />
            {{ "pages.home.list-item-5" | translate }}
            <br /><br />
            {{ "pages.home.list-item-6" | translate }}
            <br /><br />
            {{ "pages.home.list-item-7" | translate }}
          </div>
        </div>

        <div class="instructions__right">
          <app-manual-form></app-manual-form>
        </div>
      </div>
    </section>
  </div>
</main>
