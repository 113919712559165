<div class="contact-form">
  <div class="contact-form__corner-wrapper">
    <div class="contact-form__wrapper">
      <div class="contact-form__title">
        {{ "pages.contact-us.title" | translate }}
      </div>

      <div class="contact-form__text">
        {{ "pages.contact-us.description" | translate }}
      </div>

      <form [formGroup]="form">
        <div class="contact-form__form-control">
          <input
            type="email"
            class="contact-form__input"
            id="email"
            formControlName="email"
            placeholder="{{ 'shared.placeholders.email' | translate }}"
          />
        </div>

        <div class="contact-form__form-control">
          <input
            type="text"
            class="contact-form__input"
            id="subject"
            formControlName="subject"
            placeholder="{{ 'shared.placeholders.subject' | translate }}"
          />
        </div>

        <div class="contact-form__form-control">
          <input
            type="text"
            class="contact-form__input"
            id="message"
            formControlName="message"
            placeholder="{{ 'shared.placeholders.message' | translate }}"
          />
        </div>

        <button
          class="contact-form__submit"
          [disabled]="form.invalid"
          (click)="submit()"
        >
          <span *ngIf="!isLoading">{{
            "shared.buttons.send" | translate
          }}</span>
          <app-loader *ngIf="isLoading"></app-loader>
        </button>
      </form>
    </div>
  </div>
</div>
