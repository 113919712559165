import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { CurrencyService } from '@core/services/currency.service';
import { LocalStorageService } from '@core/services/local-storage.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'global-select',
  templateUrl: './global-select.component.html',
  styleUrls: ['./global-select.component.scss'],
})
export class GlobalSelectComponent implements OnInit {
  @Input() public type;
  public currency: FormControl;
  public language: FormControl;

  public currencyList = [
    { label: 'EUR', value: 'eur' },
    { label: 'USD', value: 'usd' },
    { label: 'UAH', value: 'uah' },
  ];
  public languageList = [
    { label: 'EN', value: 'en' },
    { label: 'UK', value: 'uk' },
  ];

  constructor(
    private readonly storageService: LocalStorageService,
    private readonly currencyService: CurrencyService,
    private readonly translate: TranslateService
  ) {
    // currency
    this.storageService.currency
      ? null
      : (this.storageService.currency = 'EUR');

    this.currency = new FormControl(
      this.storageService.currency
        ? {
            label: this.storageService.currency,
            value: this.storageService.currency,
          }
        : null,
      Validators.required
    );

    // language
    this.storageService.language ? null : (this.storageService.language = 'en');
    this.translate.use(this.storageService.language);

    this.language = new FormControl(
      this.storageService.language
        ? {
            label: this.storageService.language,
            value: this.storageService.language,
          }
        : null,
      Validators.required
    );
  }

  ngOnInit(): void {
    // currency
    this.currency.valueChanges.subscribe((selectedCurrency) => {
      this.currencyService.setCurrency(selectedCurrency.label);
      window.location.reload();
    });

    // language
    this.language.valueChanges.subscribe((selectedCLanguage) => {
      this.storageService.language = selectedCLanguage.value;
      this.translate.use(selectedCLanguage.value);
    });
  }
}
