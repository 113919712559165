import { IButton, ISendEmailConf } from '@core/interfaces';

const contactButton: IButton = {
  text: 'contact.btnText',
};

const form: ISendEmailConf = {
  buttonConf: contactButton,
  controlsConf: [
    {
      name: 'name',
      placeholder: 'contact.controls.name',
      type: 'text',
    },
    {
      name: 'phone',
      placeholder: 'contact.controls.phone',
      type: 'text',
    },
    {
      name: 'email',
      placeholder: 'contact.controls.email',
      type: 'email',
    },
    {
      name: 'companyName',
      placeholder: 'contact.controls.company',
      type: 'text',
    },
    {
      name: 'subject',
      placeholder: 'contact.controls.subject',
      type: 'text',
    },
    {
      noope: true,
    },
    {
      name: 'message',
      placeholder: 'contact.controls.message',
      type: 'textarea',
    },
  ],
};

export const homeContent = {
  license: {
    items: [
      {
        img: {
          src: '/assets/icons/license/dss.svg',
          alt: 'Pci dss',
        },
        text: 'license.items.dss',
      },
      {
        img: {
          src: '/assets/icons/license/gdpr.svg',
          alt: 'Gdpr',
        },
        text: 'license.items.gdpr',
      },
    ],
  },
  contact: {
    form,
  },
};
