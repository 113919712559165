import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ILink } from '@core/interfaces';

@Component({
  selector: 'app-nav-link',
  templateUrl: './nav-link.component.html',
})
export class NavLinkComponent {
  @Input() public content: ILink;

  constructor(private router: Router) {}
  private scroll(id: string): void {
    const element = document.getElementById(id);

    if (element) {
      setTimeout(() => {
        const headerHeight =
          document.querySelector('header')?.offsetHeight || 0;

        window.scrollTo({
          left: 0,
          top: element.offsetTop - headerHeight,
          behavior: 'smooth',
        });
      }, 0);
    }
  }

  public onLinkClick(content: ILink): void {
    this.router.navigate([content.path]);
  }
}
