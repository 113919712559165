import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LocalNotificationService {
  constructor(
    private toastr: ToastrService,
    private translateService: TranslateService
  ) {}

  public showNotification(
    message: string,
    status: 'toast-success' | 'toast-error' | 'toast-info',
    isTranslate: boolean = true,
    title?: string
  ) {
    const toastrConfig = {
      timeOut: 5000,
    };

    if (isTranslate) {
      this.translateService
        .get(message)
        .pipe(
          mergeMap((translatedMessage) => {
            if (title) {
              return this.translateService.get(title).pipe(
                mergeMap((translatedTitle) => {
                  return of([translatedMessage, translatedTitle]);
                })
              );
            } else {
              return of([translatedMessage, '']);
            }
          })
        )
        .subscribe(
          ([translatedMessage, translatedTitle]) =>
            this.toastr.show(
              translatedMessage,
              translatedTitle,
              toastrConfig,
              status
            ),
          (error) => console.log(error)
        );
    } else {
      this.toastr.show(message, title, toastrConfig, status);
    }
  }
}
